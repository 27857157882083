import * as React from 'react'

import { VideoElementProps } from './VideoElement'

export const BasicVideo = React.forwardRef<HTMLDivElement, VideoElementProps & React.DOMAttributes<HTMLDivElement>>(
  function BasicVideo({ className, onReady, autoplay, dangerouslySetInnerHTML }, ref) {
    const video = autoplay
      ? setParams(dangerouslySetInnerHTML?.__html as string, { mute: 1, autoplay: 1 })
      : setParams(dangerouslySetInnerHTML?.__html as string, { autoplay: 1 })

    React.useEffect(() => {
      onReady && onReady()
    }, [])

    return <div className={className} dangerouslySetInnerHTML={{ __html: video }} ref={ref} />
  },
)

type Param = {
  [id: string]: number | string
}

function setParam(videoHtml = '', param: keyof Param, value: Param[keyof Param]): string {
  const paramAlreadySet = new RegExp(`[?&]${param}=`)
  const hasQueryParams = /\?[^=]+=/
  const srcAttribute = /src="(\S+)"/

  if (paramAlreadySet.test(videoHtml)) {
    return videoHtml
  }

  const match = videoHtml.match(srcAttribute)

  if (match === null) return videoHtml
  const url = match[1]

  const lastCharacter = url.charAt(url.length - 1)
  if (lastCharacter === '?' || lastCharacter === '&') {
    return videoHtml.replace(srcAttribute, `src="${url}${param}=${value}"`)
  }

  if (hasQueryParams.test(url)) {
    return videoHtml.replace(srcAttribute, `src="${url}&${param}=${value}"`)
  }

  return videoHtml.replace(srcAttribute, `src="${url}?${param}=${value}"`)
}

function setParams(videoHtml = '', params: Param): string {
  for (const param in params) {
    const value = params[param]
    videoHtml = setParam(videoHtml, param, value)
  }
  return videoHtml
}
